
import Vue from "vue";
import KSelect from "@/components/crud/fields/KSelect.vue";
import {
  getTranslatedEnumOptions,
  TranslatedEnumOptions,
} from "@/application/util/enum";
import { TermsOfDelivery } from "@/modules/salesOrder/enum/TermsOfDelivery";

export default Vue.extend({
  name: "TermsOfDeliverySelect",
  components: { KSelect },
  data: () => ({
    termsOfDelivery: [] as TranslatedEnumOptions,
  }),
  created() {
    this.termsOfDelivery = getTranslatedEnumOptions(
      TermsOfDelivery,
      "salesOrder.termsOfDelivery"
    );
  },
});
