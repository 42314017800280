import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import { AddressBookAddress } from "@/modules/addressbook/api/addressBookAddressSave";

/**
 * Take in mind that this is the autocomplete for products that are available for customers and not clients.
 * These products are available to order. There is no autocomplete for "unfinished products". /modules/products/...
 *
 * The API team is thinking about a better name for this autocomplete
 */

export const addressBookAutocomplete = (
  clientId: number,
  data: PaginatedRequest
): Promise<AxiosResponse<PaginatedResponse<AddressBookAddress[]>>> =>
  getPaginated(`client/${clientId}/address-book/autocomplete`, data);
