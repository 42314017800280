import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosPromise, AxiosResponse } from "axios";
import { get } from "@/application/api";

/**
 * Take in mind that this is the autocomplete for products that are available for customers and not clients.
 * These products are available to order. There is no autocomplete for "unfinished products". /modules/products/...
 *
 * The API team is thinking about a better name for this autocomplete
 */

export const productAutocomplete = (
  clientId: number,
  data: PaginatedRequest
): Promise<
  AxiosResponse<
    PaginatedResponse<{ articleNumber: string; name: string; stock: number }[]>
  >
> => getPaginated(`client/${clientId}/product/autocomplete`, data);

export const searchClientProducts = (
  clientId: number,
  data: {
    items: {
      articleNumber?: string;
      ean?: string;
    }[];
  }
): AxiosPromise<{
  data: {
    articleNumber: string;
    ean?: string;
    name: string;
    stock: number;
    productTypeCode?: string;
  }[];
}> => get(`client/${clientId}/product/search`, { params: data });
