
import Vue, { VueConstructor } from "vue";
import { shippingMethodAutocomplete } from "@/modules/salesOrder/api/shippingMethod";
import KSelect from "@/components/crud/fields/KSelect.vue";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        autocomplete: {
          refresh: () => Promise<unknown>;
        };
      };
    }
  >
).extend({
  name: "ShippingMethodSelect",
  components: { KSelect },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    shippingMethods: [] as { code: string | null; name: string }[],
  }),
  watch: {
    clientId: {
      handler() {
        this.getShippingMethods();
      },
      immediate: true,
    },
  },
  methods: {
    async getShippingMethods() {
      const response = await shippingMethodAutocomplete(this.clientId, {
        perPage: 100,
        page: 1,
      });

      this.shippingMethods = [
        {
          name: this.$t("salesOrder.noShippingMethod") as string,
          code: null,
        },
        ...response.data.data,
      ];
    },
  },
});
