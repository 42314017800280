
import Vue from "vue";
import KSelect from "@/components/crud/fields/KSelect.vue";
import { getTranslatedEnumOptions } from "@/application/util/enum";
import { OrderStatus } from "@/modules/salesOrder/enum/OrderStatus";

export default Vue.extend({
  name: "OrderStatusSelect",
  components: { KSelect },
  data: () => ({
    items: getTranslatedEnumOptions(
      {
        Assigned: OrderStatus.Assigned,
        OnHold: OrderStatus.OnHold,
      },
      "salesOrder.orderStatus"
    ),
  }),
});
