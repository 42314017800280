
import Vue from "vue";

export default Vue.extend({
  name: "AddressBookAutocompleteItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    concatAddressLine() {
      return [
        this.item.deliveryAddress.companyName,
        this.item.deliveryAddress.lastName,
        this.item.deliveryAddress.email,
        [
          this.item.deliveryAddress.street,
          this.item.deliveryAddress.houseNumber,
          this.item.deliveryAddress.houseNumberAddition,
        ]
            .filter(Boolean)
            .join(" "),
        [
          this.item.deliveryAddress.postalCode,
          this.item.deliveryAddress.city,
        ]
            .filter(Boolean)
            .join(" ")
      ]
        .filter(Boolean)
        .join(", ");
    },
  },
});
