
import KPaginatedAutocomplete from "@/components/crud/fields/KPaginatedAutocomplete.vue";
import AddressBookAutocompleteItem from "./AddressBookAutocompleteItem.vue";
import Vue, { VueConstructor } from "vue";
import { addressBookAutocomplete } from "../api/addressBook";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        autocomplete: {
          refresh: () => Promise<unknown>;
        };
      };
    }
  >
).extend({
  name: "AddressBookAutocomplete",
  components: { KPaginatedAutocomplete, AddressBookAutocompleteItem },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    clientId: {
      handler() {
        this.$refs.autocomplete.refresh();
      },
    },
  },
  methods: {
    addressBookAutocomplete(data: any) {
      return addressBookAutocomplete(this.clientId, data);
    },
  },
});
