import { render, staticRenderFns } from "./ConceptSalesOrderForm.vue?vue&type=template&id=124b9cac&scoped=true&"
import script from "./ConceptSalesOrderForm.vue?vue&type=script&lang=ts&"
export * from "./ConceptSalesOrderForm.vue?vue&type=script&lang=ts&"
import style0 from "./ConceptSalesOrderForm.vue?vue&type=style&index=0&id=124b9cac&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "124b9cac",
  null
  
)

export default component.exports