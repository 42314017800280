import { AxiosResponse } from "axios";
import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";

export const shippingMethodAutocomplete = (
  clientId: number,
  data: PaginatedRequest
): Promise<
  AxiosResponse<PaginatedResponse<{ name: string; code: string }[]>>
> => getPaginated(`client/${clientId}/shipping-method/autocomplete`, data);
