
import Vue from "vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import dayjs from "dayjs";

export default Vue.extend({
  name: "KDateTimeField",
  components: { KTextField },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    internalValue: "",
  }),
  watch: {
    value: {
      immediate: true,
      handler(value: string) {
        if (!value) {
          this.internalValue = "";
          return;
        }
        this.internalValue = dayjs(value).format("YYYY-MM-DDTHH:mm:ss");
      },
    },
    internalValue: {
      immediate: true,
      handler(value: string) {
        if (!value) return value;
        this.$emit("input", dayjs(value).format("YYYY-MM-DD HH:mm:ss"));
      },
    },
  },
});
